@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  -webkit-font-smoothing: antialiased;
}

body {
  -webkit-font-smoothing: antialiased;
}

.animation-duration-10 {
  animation-duration: 10s !important;
}

.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.card-blur {
  background: linear-gradient(270deg, #f6f9fc 0, rgba(255, 255, 255, 0) 100%);
}

.preview-slider .slick-disabled {
  display: none;
  pointer-events: none;
}

.preview-slider .slick-arrow:hover {
  background: #f6f9fc;
}

.preview-slider .slick-slider {
  margin: 0 4%;
}


.preview-slider .slick-slide>div {
  margin: 0 10px;
}

.preview-slider .slick-list {
  margin: 0 -10px;
}

.lyme-primary {
  color: #529535;
}


#next-arrow-slider {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.23);
  background-color: #fff;
  position: absolute;
  right: -30px;
  padding: 30px;
  border-radius: 50%
}

#next-arrow-slider::before {
  display: block;
  content: " ";
  background-size: 30px 30px;
  background-image: url("./images/icn-arrow-right.svg");
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  position: absolute;
  opacity: 1;
  left: 15px;
  top: 15px;
}

#prev-arrow-slider {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.23);
  background-color: #fff;
  position: absolute;
  left: -30px;
  padding: 30px;
  border-radius: 50%;
  z-index: 1;
}


#prev-arrow-slider::before {
  display: block;
  content: " ";
  background-size: 30px 30px;
  background-image: url("./images/icn-arrow-left.svg");
  background-repeat: no-repeat;
  height: 35px;
  width: 35px;
  position: absolute;
  opacity: 1;
  left: 15px;
  top: 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .preview-slider .slick-arrow {
  display: none !important;
} */


.ql-editor {
  padding: 0px !important;
}

.ql-container.ql-snow {
  padding: 12px !important;
  height: 300px;
}

.ql-blank {
  height: 240px;
  max-height: 260px;
  padding: 0px !important;
}

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1
  }
}





/* STRIPE CSS */


#stripe_widget #root {
  display: flex;
  align-items: center;
}

#stripe_widget body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

#stripe_widget form {
  /* width: 30vw; */
  min-width: 500px;
  align-self: center;
  /* box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), */
  /* 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07); */
  border-radius: 7px;
  padding: 40px;
}


#stripe_widget #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#stripe_widget #payment-element {
  margin-bottom: 24px;
  margin-top: -15px;
}

#stripe_widget .StripeElement {
  margin-bottom: 10px;
}

/* Buttons and links */
#stripe_widget button {
  background: #529535;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#stripe_widget button:hover {
  filter: contrast(115%);
}

#stripe_widget button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
#stripe_widget .spinner,
#stripe_widget .spinner:before,
#stripe_widget .spinner:after {
  border-radius: 50%;
}

#stripe_widget .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

#stripe_widget .spinner:before,
#stripe_widget .spinner:after {
  position: absolute;
  content: '';
}

#stripe_widget .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

#stripe_widget .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}



.newspaper {
  max-width: 1166px;
  width: 100%;
  margin: 0px auto auto;
}

#raffle-red {
  background-image: radial-gradient(circle at top left, transparent 17px, #f4f4f418 17px),
    radial-gradient(circle at top right, transparent 17px, #f4f4f418 17px),
    radial-gradient(circle at bottom left, transparent 17px, #f4f4f418 17px),
    radial-gradient(circle at bottom right, transparent 17px, #f4f4f418 17px);
  /* box-shadow: 0 38px 14px -35px rgba(0, 0, 0, 0.3); */
  background-size: 50% 50%;
  background-position: top left, top right, bottom left, bottom right;
}

.holes-top {
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  left: 35%;
  margin-left: -25px;
  top: -20px;

  &:before,
  &:after {
    content: '';
    height: 40px;
    width: 40px;
    background-color: white;
    position: absolute;
    border-radius: 50%;
  }

  &:before {
    left: -200px;
  }

  &:after {
    left: 400px;
  }
}


.holes-bottom {
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  position: relative;
  left: 35%;
  margin-left: -25px;
  top: 20px;

  &:before,
  &:after {
    content: '';
    height: 40px;
    width: 40px;
    background-color: white;
    position: absolute;
    border-radius: 50%;
  }

  &:before {
    left: -200px;
  }

  &:after {
    left: 400px;
  }
}


#scanner {
  height: 50%;
  width: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  object-fit: cover;
}


.pulsate-live {
  background-color: #ff0000;
  color: white;
  animation: pulsate-live 2s infinite;
}

@keyframes pulsate-live {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


.body-no-scroll {
  overflow: hidden;
}